import axios from "~/plugins/axios";
import analyticsService from "~/service/analyticsService";
import CatalogService from "@/commons/service/catalogService";

export default {
  addProductsToList(
    items,
    storeId = 6,
    showSectors = global.config.showSectors
  ) {
    let params = {
      store_id: storeId,
      show_sectors: showSectors,
      items: []
    };
    try {
      for (var i = 0; i < items.items.length; i++) {
        let product = items.items[i].product;
        let quantity = items.items[i].quantity;
        let infos = items.items[i].infos;
        console.log(product.productId);
        console.log(quantity);

        params.items.push({
          productId: product.productId,
          quantity: quantity,
          infos: infos
        });

        console.log(params.items);
      }
    } catch (err) {
      console.log(err);
    }

    let url = "/ebsn/api/smart-cart/add_prod?store_id=" + storeId;

    return axios.post(url, params).then(data => {
      if (data && data.data) {
        analyticsService.addProductToList(items);

        global.EventBus.$emit("success", {
          message: global.vm.$tc(
            "list.productAddToList",
            params.items ? params.items.length : 0
          )
        });
        return data.data.data;
      } else {
        return {
          lists: [],
          page: null
        };
      }
    });
  },
  resetProds(storeId) {
    let url = "/ebsn/api/smart-cart/reset_prods?store_id=" + storeId;
    return axios.post(url).then(data => {
      if (data && data.data) {
        return data.data.data;
      } else {
        return {
          lists: [],
          page: null
        };
      }
    });
  },
  updateBillingAddress(storeId, dataBilling) {
    /*
    let params = {
      store_id: storeId,
      dataBilling: dataBilling
    };
    */
    let url = "/ebsn/api/smart-cart/update_billing_address?store_id=" + storeId;
    return axios.post(url, dataBilling).then(data => {
      if (data && data.data) {
        return data.data.data;
      } else {
        return {
          lists: [],
          page: null
        };
      }
    });
  },
  updatePersonFiscal(storeId, dataBilling) {
    /*
    let params = {
      store_id: storeId,
      dataBilling: dataBilling
    };
    */
    let url = "/ebsn/api/smart-cart/update_person_fiscal?store_id=" + storeId;

    //conversion due to Jackson mapper
    dataBilling.emailCertified = dataBilling.eMailCertified;
    return axios.post(url, dataBilling).then(data => {
      if (data && data.data) {
        return data.data.data;
      } else {
        return {
          lists: [],
          page: null
        };
      }
    });
  },
  getList(storeId, calculateStock) {
    const params = {
      store_id: storeId,
      calculate_stock: calculateStock,
      show_sectors: false
    };
    return axios
      .get("/ebsn/api/smart-cart/view", {
        params: params
      })
      .then(data => data.data.data)
      .catch(error => error);
  },

  removeItemFromList(storeId, itemId, productId) {
    let params = {
      store_id: 6,
      items: [{ itemId: itemId, quantity: 0, productId: productId }],
      show_sectors: false
    };
    let url = `/ebsn/api/smart-cart/delete_prods?store_id=` + storeId;
    params.hash = Math.random()
      .toString(36)
      .replace(/[^a-z]+/g, "")
      .substr(0, 5);
    return axios
      .post(url, params, {
        params: { show_sectors: false }
      })
      .then(data => {
        if (data && data.data) {
          //analyticsService.removeProductToList();

          return data.data.data;
        } else {
          return {
            lists: [],
            page: null
          };
        }
      });
  },
  confirm(storeId, addressId, paymentTypeId) {
    let url =
      `/ebsn/api/smart-cart/confirm?store_id=` +
      storeId +
      `&address_id=` +
      addressId +
      `&payment_type_id=` +
      paymentTypeId;
    return axios
      .post(url)
      .then(response => {
        return response.data;
      })
      .catch(err => {
        console.log(err);
      });
  },
  emptyList(list) {
    const params = { wishlistId: list.listId };
    // let items = [];
    // factory.forEachListItem(list, function (item) {
    //   items.push({ itemId: item.itemId, quantity: 0, weight: 0, productId: item.product.productId });
    // });
    // params.items = items;

    params.items = [];
    for (var i = 0; i < list.wishlistItems.length; i++) {
      let item = list.wishlistItems[i];

      params.items.push({
        productId: item.product.productId,
        quantity: item.quantity,
        weight: item.weight,
        itemId: item.itemId
      });
    }
    let url = `/ebsn/api/smart-cart/delete_prods?store_id=` + 6;
    return axios
      .post(url, params, {
        params: { show_sectors: false }
      })
      .then(data => {
        if (data && data.data) {
          // analyticsService.removeProductToList();

          return data.data.data;
        } else {
          return {
            lists: [],
            page: null
          };
        }
      });
  },
  updateProductsInList(storeId, item, newQuantity) {
    const decodedQuantity = CatalogService.encodeProductQuantity(
      item.product,
      newQuantity
    );
    let url = `/ebsn/api/smart-cart/update_prods?store_id=` + storeId;
    const params = {
      items: [{ itemId: item.itemId, quantity: decodedQuantity }]
    };

    const queryParams = {
      show_sectors: false
    };

    return axios.post(url, params, { params: queryParams }).then(data => {
      if (data && data.data) {
        return data.data.data;
      } else {
        return {
          lists: [],
          page: null
        };
      }
    });
  },
  setCartInfo(storeId, name, value, doc) {
    let params = {
      property: name,
      value: value,
      doc: doc
    };
    let url = `/ebsn/api/smart-cart/update-cart-info?store_id=` + storeId;
    return axios
      .post(url, params, {
        show_sectors: global.config.showSectors,
        store_id: storeId
      })
      .then(data => {
        return data.data.data;
      });
  },
  setCartItemInfo(storeId, itemId, name, value) {
    let params = { items: [{ itemId, infos: {} }] };
    params.items[0].infos[name] = value;
    let url = `/ebsn/api/smart-cart/update-infos?store_id=` + storeId;
    return axios
      .post(url, params, {
        show_sectors: global.config.showSectors,
        store_id: storeId
      })
      .then(data => {
        return data.data.data;
      });
  }
};
